const axios = require('axios');

let BASE_URL;
if (process.env.NODE_ENV === "development") {
    BASE_URL = "http://localhost:8080";
} else {
    BASE_URL = "https://demo.server.hook-apps.com";
}


const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'app-api-key': 'e684d2c4-eddd-44e4-b1f7-2ab9e75122ed'
    }
});

export default axiosInstance;